import React from 'react'

export const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-info">
              <h3>KFA Chimie</h3>
              <p>
                Douala Terminus
                <br /> Saint Michel <br />
                Rue Barcelone
                <br />
                <strong>Téléphone:</strong> +237 6 98 96 44 99
                <br />
                <strong>Email:</strong> {process.env.REACT_APP_INFO_EMAIL}
                <br />
              </p>
              <div class="social-links mt-3">
                <a href="#" class="twitter">
                  <i class="bx bxl-twitter"></i>
                </a>
                <a href="#" class="facebook">
                  <i class="bx bxl-facebook"></i>
                </a>
                <a href="#" class="instagram">
                  <i class="bx bxl-instagram"></i>
                </a>
                <a href="#" class="google-plus">
                  <i class="bx bxl-skype"></i>
                </a>
                <a href="#" class="linkedin">
                  <i class="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Nos spécialités</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#">Produits d'entretien</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Solvant</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#">Détachant-antirouille</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Détergent</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#">Déodorisant</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-newsletter">
              <h4>Laissez nous un message</h4>
              <p>Vous pouvez nous laisser un message rapide sur Whatsapp</p>
              <a
                href="https://wa.me/+237698964499"
                style={{ backgroundColor: "#5FFC7B" }}
                className="btn btn-secondary w-100 "
              >
                <i className="bi bi-whatsapp"></i> Whatsapp
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>KFA Chimie sarl</span>
          </strong>
          . Tous droits réservés
        </div>
        <div class="credits">
          Designed by{" "}
          <a href="https://www.linkedin.com/in/alex-nguetcha-1b2077198/">
            {" "}
            Alex Nguetcha
          </a>
        </div>
      </div>
    </footer>
  );
}
