import axios from "axios";
import { format } from "date-fns";

export const GET_PRODUCTS = "GET_PRODUCTS";

export const getProducts = (
  token,
  startDate = format(new Date(), "yyyy-MM-dd"),
  endDate = format(new Date(), "yyyy-MM-dd")
) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}products`,
        {
          params: {
            startDate,
            endDate,
          },
          headers: {
            Authorization: `Bearer 10|LlWpumb7FwzjtZFcxxaZ6f1UJRVH4U7N0ZAgWiuU`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};
