import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About } from "../../pages/About";
import { Product } from "../../pages/Product";
import { Home } from "../../pages/Home";
import { Navbar } from "../Navbar";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export const Routing = () => {

  return (
    <Router>
      <Helmet>
        <title>Produits - KFA Chimie Sarl</title>
        <meta name="title" content="KFA Chimie Sarl" />
      </Helmet>
      <Navbar />

      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="/about" exact element={<About />}></Route>
        <Route path="/product" exact element={<Product />}></Route>
        <Route path="*" exact element={<Home />}></Route>
      </Routes>
    </Router>
  );
};
