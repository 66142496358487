import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavLink } from "react-router-dom";
import "./Product.scss";
import { useState } from "react";
import { getProducts } from "../actions/product.action";
import { useEffect } from "react";

export const Product = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));
  const productData = useSelector((state) => state.productReducer);
  const [q, setQ] = useState(null);
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  //console.log(productData);
  useEffect(() => {
    try {
      dispatch(getProducts(token));
    } catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
  }, [searchParams.get("id")]);
  const getProduct = (productId) => {
    let product = null;
    productData.map((p, key) => {
      if (p.id == productId) {
        product = p;
      }
      
    });
    return product;
  };
  return (
    <>
      {searchParams.get("id") == null && (
        <Helmet>
          <link rel="canonical" href="https://kfachimie.com/product" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="L'entreprise KFA Chimie Sarl" />
          <meta
            name="description"
            content="KFA Chimie sarl est une entreprise commercialisant des produits chimiques."
          />
          <meta property="og:url" content="https://kfachimie.com/product" />
          <meta property="og:site_name" content="KFA Chimie Sarl" />
          <meta property="og:image" content="%PUBLIC_URL%/logo.svg" />
          <title>Les produits KFA Chimie Sarl</title>
        </Helmet>
      )}
      {productData.length !== 0 && searchParams.get("id") != null && (
        <Helmet>
          <link rel="canonical" href="https://kfachimie.com/product" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={getProduct(searchParams.get("id")).name} />
          <meta
            name="description"
            content={getProduct(searchParams.get("id")).description}
          />
          <meta
            property="og:url"
            content={
              "https://kfachimie.com/product?id=" + searchParams.get("id")
            }
          />
          <meta property="og:site_name" content="KFA Chimie Sarl" />
          <meta
            property="og:image"
            content={
              process.env.REACT_APP_IMAGE_BASE_PATH +
              getProduct(searchParams.get("id")).picture
            }
          />
          <title>{getProduct(searchParams.get("id")).name}</title>
        </Helmet>
      )}
      {productData.length !== 0 && searchParams.get("id") != null && (
        <div className="Widget row">
          <div className="SafeArea row  justify-content-center" data-aos="fade-in">
            {productData.length !== 0 && searchParams.get("id") && (
              <div
                key={getProduct(searchParams.get("id")).id}
                class="col-12 col-lg-10 m-sm-1 d-flex justify-content-center align-items-center flex-column flex-md-row "
              >
                <img
                  src={
                    process.env.REACT_APP_IMAGE_BASE_PATH +
                    getProduct(searchParams.get("id")).picture
                  }
                  alt=""
                  className="card-img-top img-fluid"
                />
                <div className="card-body">
                  <h4 className="card-title fw-bold">
                    {getProduct(searchParams.get("id")).name}
                  </h4>
                  <p
                    className="card-text"
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {getProduct(searchParams.get("id")).description}
                  </p>
                  <a href={"https://wa.me/+237698964499?text=Bonjour je voudrais acheter le produit "+getProduct(searchParams.get("id")).name}><button class="w-100 pay-btn"><i class="bi bi-whatsapp"></i> Achetez</button></a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row"></div>
      <div className="row p-1 mx-1 my-2 justify-content-center">
        <div className="col-12 col-sm-9">
          <input
            onChange={(e) => setQ(e.target.value)}
            placeholder="rechercher un produit dans notre catalogue"
            type="search"
            className="form-control"
            style={{ height: "55px" }}
          />
        </div>
      </div>

      <div class="row p-1 mx-1 my-2 justify-content-center products">
        {true &&
          productData.length !== 0 &&
          productData.map((p, key) => {
            const output = (
              <div
                key={key}
                class="col-xs-10 col-sm-4 col-md-4 col-lg-3 rounded-1 item border m-1"
                data-aos="fade-up"
              >
                <NavLink
                  exact
                  to={"/product?id=" + p.id}
                  className="nav-link scrollto"
                >
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture}
                    alt=""
                    className="card-img-top img-fluid mt-1"
                  />
                  <div className="card-body border-top">
                    <h4 className="card-title fw-bold">{p.name}</h4>
                    <p
                      className="card-text text-dark"
                      style={{
                        textAlign: "justify",
                        textOverflow: "ellipsis",
                        maxHeight: "3.1em",
                        lineHeight1: "1.8em",
                        wordWrap: "break-word",
                        overflow: "hidden",
                      }}
                    >
                      {p.description+"..."}
                    </p>
                    Lire la description<i class="bi bi-arrow-up-short"></i>
                    
                  </div>
                 
                </NavLink>
                <a href={"https://wa.me/+237698964499?text=Bonjour je voudrais acheter le produit "+p.name}><button class="w-100 pay-btn"><i class="bi bi-whatsapp"></i> Achetez</button></a>

              </div>
            );
            const pName = p.name;
            if (q == null || q.length == 0) {
              return output;
            } else if (
              q?.length > 0 &&
              pName.toLowerCase().includes(q.toLowerCase())
            ) {
              return output;
            }
          })}
      </div>
      <Footer />
    </>
  );
};
