import React from 'react'
import { NavLink } from 'react-router-dom'

export const Hero = () => {
  return (
    <section id="hero">
      <div class="hero-container">
        <div id="heroCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

          <div class="carousel-inner" role="listbox">

            <div class="carousel-item active" style={{ backgroundImage: `url('assets/img/slide/slide-9.jpg')` }}>
              <div class="carousel-container">
                <div class="carousel-content container">
                  <h2 class="animate__animated animate__fadeInDown">Bienvenue chez <span>KFA Chimie</span></h2>
                  <p class="animate__animated animate__fadeInUp">
                    Nous sommes ravis de vous accueillir sur notre site dédié à l'import-export de matières premières pour l'industrie chimique, ainsi qu'à la fabrication et distribution de produits d'entretien pour l'aménagement.
                    <span class="d-none d-md-block">Notre entreprise est déterminée à fournir des produits de qualité supérieure et un service client exceptionnel à tous nos partenaires commerciaux. Merci de votre intérêt et n'hésitez pas à nous contacter pour toute demande d'information supplémentaire.</span>
                  </p>

                  <NavLink exact to="/about" className="nav-link scrollto"><a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Qui sommes nous ?</a></NavLink>
                </div>
              </div>
            </div>

            <div class="carousel-item " style={{ backgroundImage: `url('assets/img/slide/slide-6.jpg')` }}>
              <div class="carousel-container">
                <div class="carousel-content container">
                  <h2 class="animate__animated animate__fadeInDown">Découvrez notre gamme de produits de qualité supérieure</h2>
                  <p class="animate__animated animate__fadeInUp">
                    Chez KFA CHIMIE, nous sommes fiers de proposer une large gamme de produits de qualité supérieure pour répondre à vos besoins en matière de matières premières et de produits d'entretien.
                    <span class="d-none d-md-block">

                      Nous avons des années d'expérience dans l'industrie chimique et sommes experts dans la production, la distribution et l'import-export de produits de qualité supérieure. Nous nous engageons à fournir à nos clients des produits fiables et de haute qualité à des prix compétitifs. Nous sommes passionnés par notre travail et nous nous efforçons constamment d'innover et d'améliorer nos produits pour répondre aux besoins de nos clients. Explorez notre gamme de produits pour trouver la solution qui répondra à vos besoins.
                    </span>
                  </p>

                  <NavLink exact to="/product" className="nav-link scrollto"><a href="#product" class="btn-get-started animate__animated animate__fadeInUp scrollto">Nos produits</a></NavLink>
                </div>
              </div>
            </div>

            <div class="carousel-item" style={{ backgroundImage: `url('assets/img/slide/slide-7.jpg')` }}>
              <div class="carousel-container">
                <div class="carousel-content container">
                  <h2 class="animate__animated animate__fadeInDown">Contactez-nous pour des solutions personnalisées</h2>
                  <p class="animate__animated animate__fadeInUp">
                    Chez KFA CHIMIE, nous sommes déterminés à offrir un excellent service à nos clients.
                    <span class="d-none d-md-block">
                      Nous sommes là pour vous aider à trouver des solutions personnalisées pour répondre à vos besoins en matière de matières premières, de production et de distribution de produits d'entretien. Nous avons une équipe dédiée qui est prête à répondre à toutes vos questions et à vous aider à trouver les produits qui conviennent le mieux à vos besoins. Nous comprenons l'importance de répondre rapidement et efficacement à vos demandes, c'est pourquoi nous vous garantissons un service client de qualité supérieure.
                    </span>
                  </p>
                  <a href="#contact" class="btn-get-started animate__animated animate__fadeInUp scrollto"><a exact href="#contact" className="text-light">Contactez nous</a></a>
                </div>
              </div>
            </div>

          </div>

          <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
          </a>

        </div>
      </div>
    </section>
  )
}
