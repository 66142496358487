import React from 'react'

export const About = () => {
  return (
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="row no-gutters">
          <div class="col-lg-6 video-box">
            <img src="assets/img/slide/slide-7.jpg" class="img-fluid" alt=""/>
            
          </div>

          <div class="col-lg-6 d-flex flex-column justify-content-center about-content">

            <div class="section-title">
              <h2>KFA Chimie Sarl</h2>
              <p>Nous sommes ravis de vous accueillir sur notre site dédié à l'import-export de matières premières pour l'industrie chimique, ainsi qu'à la fabrication et distribution de produits d'entretien pour l'aménagement.</p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bx bx-fingerprint"></i></div>
              <h4 class="title"><a href="">Notre mission</a></h4>
              <p class="description">Notre mission chez KFA CHIMIE est de fournir des matières premières et des produits d'entretien de qualité supérieure à nos clients, tout en maintenant des coûts compétitifs.</p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bx bx-gift"></i></div>
              <h4 class="title"><a href="">Notre vision</a></h4>
              <p class="description">Chez KFA CHIMIE, notre vision est de devenir le leader mondial de la production, la distribution et l'import-export de matières premières et de produits d'entretien de qualité supérieure.</p>
            </div>

          </div>
        </div>

      </div>
    </section>
  )
}
