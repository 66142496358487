import React from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from './Header'
import { Topbar } from './Topbar'


export const Navbar = () => {
  const location = useLocation()
  const show = location.pathname!=="/account"
  return (
    <>{show&&<div className='Navbar'>
        <Topbar/>
        <Header/>
    </div>}</>
  )
}
