import { useEffect, React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/product.action";
import { NavLink } from "react-router-dom";
import "./TopProduct.scss";

export const TopProduct = () => {
  const productData = useSelector((state) => state.productReducer);
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  //console.log(productData);
  useEffect(() => {
    try {
      dispatch(getProducts(token));
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <section id="topProduct" className="topProduct">
      <div class="m-2">
        <div class="section-title mt-5">
          <h2>Explorez nos meilleurs produits</h2>
        </div>

        <div class="row mx-1 my-1 justify-content-center products" data-aos="fade-up">
          {productData.length !== 0 &&
            productData.map((p, key) => {
              if (key < 6) {
                return (
                  <div
                    key={key}
                    class="col-11 col-xs-10 col-sm-4 col-md-4 col-lg-3 rounded-1 item border m-1"
                  >
                    <img
                      src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture}
                      alt=""
                      
                      className="card-img-top img-fluid mt-1"
                    />
                    <div className="card-body border-top">
                      <h4 className="card-title fw-bold">{p.name}</h4>
                      <p
                        className="card-text"
                        style={{
                          textAlign: "justify",
                          textOverflow: "ellipsis",
                          maxHeight: "3.1em",
                          lineHeight1: "1.8em",
                          wordWrap: "break-word",
                          overflow: "hidden",
                        }}
                      >
                        {p.description}
                      </p>
                      <NavLink
                        exact
                        to={"/product?id=" + p.id}
                        className="nav-link btn w-100 btn-primary text-light scrollto"
                      >
                        Voir plus...
                      </NavLink>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </section>
  );
};
