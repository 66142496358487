import React from 'react'

export const Faq = () => {
  return (
    <section id="faq" class="faq section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Questions fréquement posées</h2>
        </div>

        <div class="row  d-flex align-items-stretch">

          <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="100">
            <h4>Quels sont les produits chimiques que vous distribuez et vendez ?</h4>
            <p>
              Nous distribuons une large gamme de matières premières pour l'industrie chimique, ainsi que des produits d'entretien et de nettoyage. Vous pouvez consulter notre catalogue de produits pour en savoir plus.
            </p>
          </div>

          <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="200">
            <h4>Comment puis-je passer une commande ?</h4>
            <p>
              Vous pouvez passer une commande en nous envoyant un e-mail ou en nous appelant directement. Nous vous fournirons toutes les informations nécessaires pour que vous puissiez passer votre commande en toute confiance.
            </p>
          </div>

          <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="300">
            <h4>Proposez-vous des solutions personnalisées pour les clients ?</h4>
            <p>
              Oui, nous proposons des solutions personnalisées pour répondre aux besoins de nos clients. Notre équipe est prête à travailler avec vous pour comprendre vos besoins et vous proposer des solutions sur mesure.
            </p>
          </div>

          <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="400">
            <h4>Quelles sont les zones géographiques que vous couvrez pour l'import-export ?</h4>
            <p>
              Nous couvrons une large gamme de zones géographiques pour l'import-export de nos produits. N'hésitez pas à nous contacter pour discuter de vos besoins spécifiques.
            </p>
          </div>

          <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="500">
            <h4>Comment puis-je obtenir des informations sur les spécifications techniques des produits ?</h4>
            <p>
            Nous pouvons vous fournir toutes les informations techniques dont vous avez besoin sur nos produits. N'hésitez pas à nous contacter pour discuter de vos besoins en matière de spécifications techniques.
            </p>
          </div>

          <div class="col-lg-6 faq-item" data-aos="fade-up">
            <h4>Quelles sont les conditions de paiement que vous proposez ?</h4>
            <p>
            Nous proposons différentes options de paiement, y compris les virements bancaires et les paiements en ligne. Nous pouvons discuter avec vous des options qui conviennent le mieux à vos besoins.
            </p>
          </div>

        </div>

      </div>
    </section>
  )
}
