import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Header.scss";

export const Header = () => {
  const token = window.localStorage.getItem("token");
  const [showMenu, setShowMenu] = useState(false);

  const openMobileMenu = () => {
    setShowMenu(true);
  };

  return (
    <header
      id="header"
      class="d-flex align-items-center border-1 border-bottom border-primary"
    >
      {showMenu && (
        <div
          className={"Modal"}
          role="dialog"
          onClick={() => setShowMenu(false)}
          data-aos="fade-in"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="container-fluid">
                  <ul className="text-black-50 fw-bolder">
                    <li>
                      <NavLink exact to="/" className="nav-link scrollto">
                        Accueil
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/product"
                        className="nav-link scrollto"
                      >
                        Nos produits
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/about" className="nav-link scrollto">
                        A propos
                      </NavLink>
                    </li>

                    <li>
                      <a exact href="/#contact" className="nav-link scrollto">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="container d-flex align-items-center">
        <div class="logo me-auto fw-bolder fs-3">
          <a href="/">
            <img src="./assets/logo.png" alt="" />
          </a>{" "}
          KFA
        </div>

        <nav
          id="navbar"
          class="navbar navbar-expand-lg navbar-dark shadow-5-strong"
        >
          <ul className="text-black-50 fw-bolder">
            <li>
              <NavLink exact to="/" className="nav-link scrollto">
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/product" className="nav-link scrollto">
                Nos produits
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/about" className="nav-link scrollto">
                A propos
              </NavLink>
            </li>

            <li>
              <a exact href="/#contact" className="nav-link scrollto">
                Contact
              </a>
            </li>
          </ul>
          <i
            onClick={() => openMobileMenu()}
            class="bi bi-list mobile-nav-toggle"
          ></i>
        </nav>
      </div>
    </header>
  );
};
