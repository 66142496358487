import React from 'react'

export const Topbar = () => {

    return (
      <section
        id="topbar"
        class="d-flex align-items-center text-white border-bottom border-dark"
      >
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope-fill d-none d-md-block"></i>
            <a
              className="d-none d-md-block"
              href={"mailto:" + process.env.REACT_APP_INFO_EMAIL}
            >
              {" "}
              {process.env.REACT_APP_INFO_EMAIL}
            </a>
            <i class="bi bi-phone-fill phone-icon"></i>{" "}
            <a href={"tel:" + process.env.REACT_APP_INFO_CONTACT}>
              {" "}
              {process.env.REACT_APP_INFO_CONTACT}
            </a>
          </div>
          <div class="social-links d-none d-md-block">
            <a href={process.env.REACT_APP_TWITTER} class="twitter">
              <i class="bi bi-twitter"></i>
            </a>
            <a href={process.env.REACT_APP_FACEBOOK} class="facebook">
              <i class="bi bi-facebook"></i>
            </a>
            <a href={process.env.REACT_APP_INSTAGRAM} class="instagram">
              <i class="bi bi-instagram"></i>
            </a>
            <a href={process.env.REACT_APP_LINKEDIN} class="linkedin">
              <i class="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </section>
    );
}
