import React, { useState } from 'react'

export const Contact = () => {
  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  return (
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact Us</h2>
        </div>

        <div class="row">

          <div class="col-lg-6 d-flex" data-aos="fade-up">
            <div class="info-box">
              <i class="bx bx-map"></i>
              <h3>Notre Addresse</h3>
              <p>Douala Terminus, Saint Michel Rue Barcelone</p>
            </div>
          </div>

          <div class="col-lg-3 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div class="info-box">
              <i class="bx bx-envelope"></i>
              <h3>Envoyer-nous un email</h3>
              <p>{process.env.REACT_APP_INFO_EMAIL}<br/></p>
            </div>
          </div>

          <div class="col-lg-3 d-flex" data-aos="fade-up" data-aos-delay="200">
            <div class="info-box ">
              <i class="bx bx-phone-call"></i>
              <h3>Appelez-nous</h3>
              <p>+237 6 98 96 44 99<br/>+237 6 99 97 59 18</p>
            </div>
          </div>

        
        </div>

      </div>
    </section>
  )
}
