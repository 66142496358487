import React, { useEffect } from "react";
import { Contact } from "../components/Contact";
import { Faq } from "../components/Faq";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { TopProduct } from "../components/TopProduct";
import { About } from "../components/About";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <div className="Home">
      <Helmet>
        <link rel="canonical" href="https://kfachimie.com/" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Accueil - KFA Chimie Sarl" />
        <meta
          name="description"
          content="KFA Chimie SARL est une entreprise spécialisée dans l'import-export de matières premières pour l'industrie chimique, ainsi que dans la fabrication et la distribution de produits d'entretien pour l'aménagement. L'objectif est de fournir des produits de qualité supérieure et un service client exceptionnel à tous nos partenaires commerciaux."
        />
        <meta property="og:url" content="https://kfachimie.com/" />
        <meta property="og:site_name" content="KFA Chimie Sarl" />
        <meta property="og:image" content="https://kfachimie.com/logo.png" />
        <title>Accueil - KFA Chimie</title>
      </Helmet>
      <Hero />
      {true && <TopProduct />}
      <About />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};
