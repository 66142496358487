import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer";
import "./About.scss";

export const About = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://kfachimie.com/about" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="L'entreprise KFA Chimie Sarl" />
        <meta
          name="description"
          content="KFA Chimie sarl est une entreprise commercialisant des produits chimiques."
        />
        <meta property="og:url" content="https://kfachimie.com/about" />
        <meta property="og:site_name" content="KFA Chimie Sarl" />
        <meta property="og:image" content="%PUBLIC_URL%/logo.svg" />
        <title>L'entreprise KFA Chimie Sarl</title>
      </Helmet>
      <div className="row About text-light p-5" data-aos="fade-left">
        <div className="col-12 col-md-6 p-0 p-md-5 text-left">
          <h2 className="text-uppercase">A propos de nous</h2>
          <p className="fw-bolder">
            CONSTRUIRE UNE MARQUE
            <br /> QUI SOIT PLUS Q&rsquo;UNE MARQUE{" "}
          </p>
        </div>
        <div className="col-12 col-md-6 p-0 p-md-5 text-justify">
          KFA CHIMIE est une société à responsabilité limitée qui a été créée en 2015 par un chimiste professionnel appelé Kuate Fokam Alexandre. Au début, l'entreprise se spécialisait dans la distribution de matières premières et la production de produits d'entretien de qualité. En 2020, la société a élargi ses activités en entrant dans le domaine de l'import-export, qui est aujourd'hui une activité clé de l'entreprise.
        </div>
      </div>
      <div className="Mission row m-2 m-md-5 " data-aos="fade-up">
        <div className="col-12 col-md-8 mt-3 order-1 order-md-2">
          Notre mission chez KFA CHIMIE est de fournir des matières premières et des produits d'entretien de qualité supérieure à nos clients, tout en maintenant des coûts compétitifs. Nous importons et produisons des matières premières de qualité, en utilisant des technologies modernes pour garantir la satisfaction de nos clients. Nous le faisons parce que nous croyons que tout le monde mérite d'avoir accès à des produits de qualité supérieure à des prix abordables.
<br/><br/>
          Par exemple, notre mission est d'aider les industries locales à accéder à des matières premières de qualité et à des produits d'entretien abordables, afin de soutenir leur croissance économique. Nous le faisons en utilisant des pratiques durables, respectueuses de l'environnement, pour promouvoir une économie verte et une société plus responsable.
        </div>
        <div className="col-12 col-md-3 mt-3 order-sm-2 order-md-2">
          <h2 className="fw-bold" style={{ color: "#3666af" }}>
            NOTRE MISSION
          </h2>
        </div>
      </div>
      <div className="Vision row text-light p-5" data-aos="fade-right">
        <div className="col-12  p-1 mt-5">
          <h2 className="fw-bold mt-5">VISION</h2>
          <br />
          Chez KFA CHIMIE, notre vision est de devenir le leader mondial de la production, la distribution et l'import-export de matières premières et de produits d'entretien de qualité supérieure. Nous sommes déterminés à fournir à nos clients des produits de qualité, à des prix abordables, tout en étant une entreprise socialement responsable et respectueuse de l'environnement.
        </div>
      </div>
      <Footer />
    </>
  );
};
